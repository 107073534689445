<template>
  <div class="single-bridge-common div-scroll">
    <div class="process-top">
      <div class="process-con">
        <chart-two :value="chartData1"></chart-two>
      </div>
      <div class="process-con">
        <chart-three :value="chartData2"></chart-three>
      </div>
      <div class="process-con">
        <chart-four :value="chartData3"></chart-four>
      </div>
    </div>
    <div class="tit-search flex">
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="search()"
          @delQuery="delQuery"
      ></c-search>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:handleStatus="{ row }">
          <span>{{row.handleStatus === 0 ? '未处理' : (row.handleStatus === 1 ? '已处理' : (row.handleStatus === 2 ? '已忽略' : ''))}}</span>
        </template>
        <template v-slot:custom="{ row }">
          <span class="opt-detail" @click="getDetail(row)">详情</span>
          <span class="opt-update" v-if="row.handleStatus === 0" style="color:#F4BB4E" @click="process(row)">处理</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="报警处理"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="660px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="24">
              <el-form-item label="报警类型" prop="warnType">
                <el-select size="small" v-model="form.model.warnType" placeholder="请选择报警类型">
                  <el-option label="系统误报" :value="0"></el-option>
                  <el-option label="真实报警" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="处理说明" prop="explain">
                <el-input type="textarea" v-model="form.model.explain"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :close-on-click-modal="false"
        title="详情"
        :visible.sync="visible"
        :destroy-on-close="true"
        width="1100px"
    >
      <div class="health-chart">
        <chart-one ref="chartOne" v-loading="loading" style="height: 400px" :value="chartData"></chart-one>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  import chartOne from "./chart/chart01"
  import chartTwo from "./chart/chart02"
  import chartThree from "./chart/chart03"
  import chartFour from "./chart/chart04"
  let mPage = MPage({
    pagePath: "/sensor/warn/listPage",
    pageDelPath: "/sensor/warn/delete",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    components:{chartOne,chartTwo,chartThree,chartFour},
    data() {
      let { searchItems,tableColumns } = Configs(this);
      return {
        searchItems,
        tableColumns,
        dialogVisible: false,
        visible:false,
        title:'',
        pageData:[],
        chartData: {
          legend:[],
          markLineList:[]
        },
        loading:false,
        form:{
          model:{
            explain:'',
            warnType:''
          },
          rules:{
            warnType:[
              { required: true, message: '请选择报警类型', trigger: 'blur' }
            ]
          }
        },
        sensorList:[],
        props:{
          multiple: true,
          label:'name',
          value:'id',
          children:'sensorDetailList'
        },
        sensorDetailList:[],
        isAdd:true,
        api:'',
        chartData1:{data:[]},
        chartData2:{xaxis:[],yaxis:[]},
        chartData3:{xaxis:[],yaxis:[]},
        multipleSelection: []
      };

    },
    created() {
      this.getSensor()
      this.getAlarmSta()
      this.getStatusSta()
      this.getLevelSta()
    },
    methods: {
      delQuery() {
        this.$confirm("此操作将永久删除当前条件下的全部数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(() => {
          this.$http.post("/sensor/warn/delQuery", this.pageParams).then(res => {
            if(res.success){
              this.$message({
                message: "恭喜你，删除成功！",
                type: "success"
              });
              this.pageGet(true);
            } else {
              this.$message.error(
                  `删除数据失败，${res.msg}`
              );
            }
          })
        }).catch(err => console.warn(err));
      },
      delBatch() {
        let idList = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          idList.push(this.multipleSelection[i].id);
        }
        this.$confirm("此操作将永久删除选中数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(() => {
          this.$http.post("/sensor/warn/delBatch", idList).then(res => {
            if(res.success){
              this.$message({
                message: "恭喜你，删除成功！",
                type: "success"
              });
              this.pageGet(true);
            } else {
              this.$message.error(
                  `删除数据失败，${res.msg}`
              );
            }
          })
        }).catch(err => console.warn(err));
      },
      selectionChange(e) {
        this.multipleSelection = e;
      },
      getSensor(){
        this.$http.get("/sensorAnalysis/sensorType/list").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = res.data
              this.searchItems[3].options = []
              for (let item of this.sensorList){
                this.searchItems[3].options.push({label: item.name, value: item.id})
              }
            }
          }
        })
      },
      getAlarmSta(){
        this.$http.get('/sensor/warn/statistics/item').then(res => {
          if (res.success){
            this.chartData1.data = res.data.data
          }
        })
      },
      getStatusSta(){
        this.$http.get('/sensor/warn/statistics/status').then(res => {
          if (res.success){
            this.chartData2 = res.data
          }
        })
      },
      getLevelSta(){
        this.$http.get('/sensor/warn/statistics/level').then(res => {
          if (res.success){
            this.chartData3 = res.data
          }
        })
      },
      search(){
        if (this.pageParams.time && this.pageParams.time.length > 0){
          this.pageParams.startTime = this.pageParams.time[0]
          this.pageParams.endTime = this.pageParams.time[1]
        } else {
          this.pageParams.startTime = ''
          this.pageParams.endTime = ''
        }
        this.pageGet(true)
      },
      getDetail(data){
        let self = this
        self.loading = true
        self.$http.post('/sensor/warn/detail',{pointId:data.sensorDetailId,dateTime:data.datetime}).then(res => {
          if (res.success){
            if (res.data){
              res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
              self.chartData = res.data
            }
            self.visible = true
          } else {
            this.$message.error(res.mag)
          }
          this.loading = false
        })
      },
      neglect(data){
        this.$http.get('/sensor/warn/ignore',{params:{id:data.id}}).then(res => {
          if (res.success){
            this.$message.success('处理成功')
            this.pageGet()
          }
        })
      },
      process(data) {
        this.form.model.id = data.id
        this.form.model.explain = ''
        this.dialogVisible = true
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('/sensor/warn/handler',this.form.model).then(res=>{
              if(res.success){
                this.$message.success("处理成功");
                this.pageGet()
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-detail{
    margin-right: 10px;
  }
  .opt-update{
    margin-right: 10px;
  }
  .opt-update{
    cursor: pointer;
  }
  .process-top{
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: space-between;
  }
  .process-con{
    width: 32%;
    height: 100%;
  }
</style>
