<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      text: String,
      subtext: String,
    },
    data() {
      return {

      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      this.loadEcharts(this.value);
    },
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        self.$nextTick(() => {
          // self.yAxisName = val.unit
          let option = {
            title: {
              text: '报警级别统计',
              left: 'center'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'category',
              data: val.xaxis
            },
            yAxis: {
              type: 'value'
            },
            grid:{
              left:60
            },
            series: [
              {
                data: val.yaxis,
                type: 'bar',
                barWidth:20
              }
            ]
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: 100%;
  }
</style>
