import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "报警时间",
      span: 8,
      model: "time",
      clearable: true,
      type: "datetimerange",
      options: []
    },
    {
      label: "报警等级",
      span: 3,
      model: "warnLevel",
      clearable: true,
      type: "select",
      options: [
        {label:'一级',value:1},
        {label:'二级',value:2},
        {label:'三级',value:3}
      ]
    },
    {
      label: "处理状态",
      span: 3,
      model: "handleStatus",
      clearable: true,
      type: "select",
      options: [
        {label:'未处理',value:0},
        {label:'已处理',value:1},
        {label:'已忽略',value:2}
      ]
    },
    {
      label: "传感器",
      span:5,
      type: "select",
      model: "sensorId",
      options: [],
      clearable: true,
    },
    {
      label: "报警类型",
      span: 4,
      model: "warnType",
      clearable: true,
      type: "select",
      options: [
        {label:'系统误报',value:0},
        {label:'真实报警',value:1}
      ]
    },
  ];
  const tableColumns = [
    {
      label: "传感器编号",
      prop: "sensorDetailName",
      align: "center",
      show: true
    },
    {
      label: "传感器名称",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "传感器位置",
      prop: "subName",
      align: "center",
      show: true
    },
    {
      label: "警报等级",
      prop: "warnLevel",
      align: "center",
      show: true
    },
    {
      label: "警报时间",
      prop: "datetime",
      align: "center",
      show: true,
      width:160
    },
    {
      label: "处理状态",
      prop: "handleStatus",
      name:"handleStatus",
      align: "center",
      show: true
    },
    {
      label: "处理时间",
      prop: "handleTime",
      align: "center",
      show: true,
      width:160
    },
    {
      label: "处理说明",
      prop: "handleExplain",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
